<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <!-- <img class="topStripClass" src="../assets/imgs/topStrip.png" alt=""> -->
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <!-- <div style="position:absolute;z-index:1;left:10px;top:10px;">
      <img src="../assets/imgs/titleTopImg.png" width="100%" height="100">
    </div>
    <div style="position:absolute;z-index:2;left:60px;top:60px;">
      <img src="../assets/imgs/topStrip.png" width="40%" height="100">
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
