<template>
  <div class="largeScreen">
    <div id="mapContainer"></div>
    <!-- 顶部view -->
    <div class="topViewClass">
      <div class="topStripView">
        <!-- <img class="topStripClass" src="../assets/imgs/topStrip.png" alt=""> -->
        <div class="highlightView">
          <img class="highlightClass" src="../assets/imgs/HighlightImg.png">
          <div class="highlightText">几维空间会员</div>
        </div>
        <div class="highlightView" @mouseover="mouseEnter" @mouseout="mouseLeave" @click="goHiYouCaiFunc">
          <img class="routineClass" :src="highLightImg">
          <div class="normalText">嗨优才会员</div>
        </div>
        <div class="timeDivClass">
          <div class="timeClass"> {{dateStr}} </div>
          <div class="timeClass"> {{weekStr}} </div>
        </div>
      </div>
    </div>
    <!-- 底部view -->
    <!-- 左边容器view -->
    <div class="leftView">
      <div class="leftTopView">
        <div class="arrowView">
          <img class="arrowImgClass" src="../assets/imgs/arrowImg.png" alt="">
          <img class="diskImgClass" src="../assets/imgs/diskImg.png" alt="">
        </div>
        <!-- 总自习室、会员数view -->
        <div class="overallClass">
          <div style="display:flex; margin: auto">
            <div class="zixiClass">
              总自习室
            </div>
            <div class="numberViewClass" v-for="(item, index) in studyNumberArr" :key="index + '2'">
              <img class="numberImgClass" src="../assets/imgs/numberBottom.png" alt="">
              <div class="numberTextClass">
                {{item}}
              </div>
            </div>
            <div class="zixiClass rightZiXXi">
              总会员数
            </div>
            <div class="numberViewClass" v-for="(item, index) in memberNumberArr" :key="index + '1'">
              <img class="numberImgClass" src="../assets/imgs/numberBottom.png" alt="">
              <div class="numberTextClass">
                {{item}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧信息view -->
    <div class="rightView">
      <!-- 右上图片 -->
      <div class="seleStudyView">
        <img class="selfStudyClass" src="../assets/imgs/selfStudyImg.png" alt="">
        <img class="columnClass" src="../assets/imgs/columnImg.png" alt="">
        <div class="selfStudyName" v-if="provinceAreaType === 'areaLevel'">{{cityName}}-{{roomName}}数据统计</div>
        <div class="selfStudyName" v-else-if="provinceAreaType === 'roomLevel'"> {{cityName}}-自习室数据统计 </div>
        <div class="selfStudyName" v-else>{{titleText}}</div>
      </div>
      <!-- 自习室店地址 -->
      <div class="roomViewClass" v-if="provinceAreaType == 'areaLevel'">
        <img class="roomAddressClass" src="../assets/imgs/roomAddress.png" alt="">
        <div class="roomViewTextClass">{{areaAdress}}</div>
      </div>
      <!-- 学员信息 -->
      <div class="studentBackView" v-if="provinceAreaType === 'areaLevel'">
        <div class="studyInfoView" v-for="(item, index) in memberInfoArr" :key="index">
          <div class="itemTopView">
            <div class="studentClass">{{item.username}}</div>
            <div class="genderAgeClass" v-if="item.gender == 1"> 男/{{item.age}}岁</div>
            <div class="genderAgeClass" v-if="item.gender == 2"> 女/{{item.age}}岁</div>
            <div class="genderAgeClass" v-if="item.gender == 0"> 未知/{{item.age}}岁</div>

          </div>
          <div class="middleView" v-if="item.country && item.country.length > 0">
            <div class="wantCountryClass">意向国家：</div>
            <div v-for="(item, index) in item.country" :key="index + 9" class="labelView"> {{item}} </div>
          </div>
          <div class="itemBottomView">
            <img class="addressImg" src="../assets/imgs/address.png" alt="">
            <div class="studentAddressView">{{item.login_address}}</div>
          </div>
        </div>
      </div>
      <!-- 市级自习室列表 -->
      <div class="infoView" v-else-if="provinceAreaType === 'roomLevel'">
        <div class="studyItemView" v-for="(item, index) in roomInfoArr" :key="index" v-on:click="roomClick(item)">
          <div class="itemTopView">
            <div class="studyName">{{item.store_name}}</div>
            <div class="peopleNumber">{{item.people_number}}人</div>
          </div>
          <div class="itemBottomView">
            <img class="addressImg" src="../assets/imgs/address.png" alt="">
            <div class="studentAddressView">{{item.address}}</div>
          </div>
        </div>
      </div>
      <!-- 右侧自习室循环view -->
      <div class="infoView" v-else>
        <div class="studyItemView" v-for="(item, index) in studyInfoArr" :key="index" v-on:click="roomClick(item)">
          <div class="itemTopView">
            <div class="studyName">{{item.title}}</div>
            <div class="peopleNumber">{{item.people}}人</div>
          </div>
          <div class="itemBottomView">
            <!-- <img class="addressImg" src="../assets/imgs/address.png" alt=""> -->
            <div class="itemAddressView">自习室数量：{{item.number}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 左下雷达 -->
    <div class="radarView">
      <img class="radarImgClass" src="../assets/imgs/radarImg.png" alt="">
      <img class="scanningClass" src="../assets/imgs/scanningImg.png" alt="">
    </div>

    <!-- 返回上一层 -->
    <div class="backLevelView">
      <img v-if="backBool" class="backLevelImgClass" src="../assets/imgs/backLevelImg.png" alt="" @click="backClick">
      <img class="enlargeClass" src="../assets/imgs/enlargeImg.png" @click="enlargeMapClick" alt="">
      <img class="enlargeClass narrowClass" src="../assets/imgs/narrowImg.png" @click="narrowMapClick" alt="">
    </div>
  </div>
</template>

<script>
let infoWindow
import request from '@/utils/request'

import AMapLoader from '@amap/amap-jsapi-loader'
// import AMap from '@amap/amap-jsapi-loader'
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '326e626afa5216e969d4aa54f339cbe2',
}
export default {
  data(){
    return{
      highLightImg: require('../assets/imgs/routineImg.png'),
      studyNumberArr: [],
      memberNumberArr: [],

      nowTimeStr: '',
      timer: null, //  定时器

      map: null,//地图对象
      marker: null,//标记对象
      loaded: false,
      infoWindow: null,//弹窗对象
      coordinateArr: [{longitude: 101, latitude: 39}, {longitude: 104, latitude: 40}],
      allowCollision: false,//标注避让marker
      zoom: 4.8,
      markers: [],
      infoWindowArr: [],
      showInfoArr: [],
      layer: null,

      backBool: false,
      provinceAreaType: '',

      titleText: '几维空间全国自习室数据统计',
      cityName: '',
      roomName: '',
      areaAdress: '',
      studyStr: '',
      memberStr: '',
      // {name: '金创大厦自习室', people: '201', roomNumber: '6', id: 101, longitude: 114.34, latitude: 39.28},
      // {name: '万达广场自习室', people: '938', roomNumber: '14', id: 102, longitude: 117.34, latitude: 38.28},
      // {name: '火炬广场自习室', people: '31', roomNumber: '2', id: 103, longitude: 112.34, latitude: 36.28},
      // {name: '世纪公园自习室', people: '3752', roomNumber: '58', id: 104, longitude: 110.34, latitude: 35.28},
      studyInfoArr: [],
      // {name: '方', people: '201', roomAddress: '站前街8号', id: 101, longitude: 114.34, latitude: 39.28,
      //   universities: ['美国', '澳大利亚'], gender: '男', age: 19},
      // {name: '周', people: '938', roomAddress: '裕华路3号', id: 102, longitude: 117.34, latitude: 38.28,
      //   universities: ['加拿大', '日本'], gender: '女', age: 21},
      // {name: '李', people: '31', roomAddress: '裕华东路8号', id: 103, longitude: 112.34, latitude: 36.28,
      //   universities: ['新西兰', '澳大利亚'], gender: '女', age: 24},
      // {name: '雷', people: '3752', roomAddress: '槐安路', id: 104, longitude: 110.34, latitude: 35.28,
      //   universities: ['英国', '美国', '德国'], gender: '男', age: 20},
      areaDataArr: [],
      memberInfoArr: [],
      roomInfoArr: [],
      typeStr: '0',
      region_id: '',
      areaRegion_id: '',//  区域id

      // 腾讯地图国家数据
      txCountryArr: [
        { id: 1, position: { lat: 30.523253, lng: 114.313195 }},
        { id: 1, position: { lat: 31.823253, lng: 117.313195 }},
        { id: 2, position: { lat: 24.023253, lng: 110.353195 }}],
      txCityArr: [
        { id: 1, position: { lat: 38.033253, lng: 114.263195 }},
        { id: 1, position: { lat: 36.893253, lng: 113.473195 }},
        { id: 2, position: { lat: 40.473253, lng: 114.523195 }},
        { id: 1, position: { lat: 39.843253, lng: 118.103195 }},
      ],//  腾讯地图城市数据
      txAreaArr: [
        { id: 1, position: { lat: 38.033253, lng: 114.473191 }},
        { id: 1, position: { lat: 38.013253, lng: 114.283195 }},
      ],//  腾讯,地图区县数据
      dateStr: '',
      weekStr: ''
    }
  },
  created(){
    this.getCountryData()
    // 右上角时间
    setInterval(() => {
      this.getNowTime();
    }, 1000);
    this.handleCoorFunc()
    // 显示腾讯地图
    this.initTencentMap()
    // let studyStr = '14'.padStart(7, '0')
    // this.studyNumberArr = Array.from(studyStr)
    // let memberStr = '5624'.padStart(7, '0')
    // this.memberNumberArr = Array.from(memberStr)
    console.log('12345'.padStart(7, '0'))
  },
  methods:{
    // 全国/城市数据
    getCountryData() {
      request({
        url: 'study.Store/largeScreen?type=' + this.typeStr + '&region_id=' + this.region_id,
        method: 'GET',
      }).then(res => {
        if(res.code == 200){
          console.log(res)
          this.studyInfoArr = res.data.store_list
          this.titleText = res.data.title

          let store = res.data.total_store + ''
          let studyStr = store.padStart(7, '0')
          this.studyNumberArr = Array.from(studyStr)

          let people = res.data.total_people + ''
          let memberStr = people.padStart(7, '0')
          this.memberNumberArr = Array.from(memberStr)

          if(this.region_id){
            this.getTXCityInfo()
          } else {
            this.getTXProvinceInfo()
          }
        }
      })
    },
    // 各区数据
    getAreaData(){
      request({
        url: 'study.Store/getDistrictStoreList?region_id=' + this.areaRegion_id,
        method: 'GET',
      }).then(res => {
        if(res.code == 200){
          this.areaDataArr = res.data.store_list
          this.roomInfoArr = res.data.store_list
          let store = res.data.total_store + ''
          let studyStr = store.padStart(7, '0')
          this.studyNumberArr = Array.from(studyStr)

          let people = res.data.total_people + ''
          let memberStr = people.padStart(7, '0')
          this.memberNumberArr = Array.from(memberStr)

          this.titleText = res.data.region_name + '-' + this.areaDataArr[0].store_name + '数据统计'
          this.cityName = res.data.region_name
          this.areaAdress = this.areaDataArr[0].address
          this.roomName = this.areaDataArr[0].store_name
          this.memberInfoArr = this.areaDataArr[0].people_list
          this.getTXAreaInfo()
        }
      })
    },
    // 腾讯地图
    initTencentMap(){
      this.$nextTick(()=>{
        //获取dom节点
        var drawContainer = document.getElementById('mapContainer');
        // var center = new window.TMap.LatLng(39.984104, 116.307503);//设置中心点坐标   lat: 23.228237   lng: 113.273747
        //如果是回显的话直接把经纬度传进去就行
        var center = new TMap.LatLng(38.528173, 112.230349)
        this.map = new TMap.Map(drawContainer, {
          zoom: this.zoom,
          // pitch: 40,
          center: center,
          draggable: true,
          scrollable: true,
          mapStyleId: "style1"
        });
        this.getTXProvinceInfo()
      })
    },

    // 加载腾讯地图国家地图数据
    getTXProvinceInfo(){
      var geometries = this.studyInfoArr.map(item => {
        return {
          id: item.region_id,
          position: new TMap.LatLng(item.lat, item.lng),
          "styleId": 'marker',  //指定样式id
          // content: item.address
        }
      })
      // 图层类
      this.markerLayer = new window.TMap.MultiMarker({
        map: this.map,
        styles: {
          "marker": new TMap.MarkerStyle({
            "width": 10,
            "height": 30,
            "anchor": { x: 16, y: 32 },
            "src": require('../assets/imgs/taggingImg.png')
          })
        },
        geometries: geometries
      })
      this.markerLayer.updateGeometries(geometries)

      let that = this
      var eventClick = function(evt) {
        console.log(evt.geometry.id)

        that.provinceAreaType = 'cityLevel'
        that.map.setCenter(new TMap.LatLng(that.studyInfoArr[0].lat, that.studyInfoArr[0].lng))
        // that.map.setCenter(new TMap.LatLng(33.425845, 107.404581))

        that.zoom = 7.4
        that.map.zoomTo(that.zoom);

        that.markerLayer.setGeometries([])
        that.infoWindowArr.forEach(item => {
          item.close()
        });
        infoWindow.close()
        that.backBool = true

        that.typeStr = 1
        that.region_id = evt.geometry.id

        that.getCountryData()
      }
      this.markerLayer.on('click', eventClick)

      var mouseoverFunc = function(e){
        console.log(e)
        let name;
        let number;
        let people;
        that.studyInfoArr.forEach(item => {
          if(e.geometry.id == item.region_id){
            name = item.title
            number = item.number
            people = item.people
          }
        });
        infoWindow = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(e.geometry.position.lat, e.geometry.position.lng),
          zIndex: 10, //信息窗的z-index值
          // showShadow: false,
          offset: { x: -30, y: -108 }, //向上偏移35像素坐标，向左偏移3像素坐标
          content: `<div ><img src='${require('../assets/imgs/countryInfoImg.png')}' style='display: block; position: absolute;'></img>
          <p class="nameClass" style='position: absolute; top: 13px; left: 10px; width: 128px; text-align: center; word-break: keep-all; color: #FFFFFF; font-size: 12px'>${name}</P>
          <p class="nameClass" style='position: absolute; top: 44px; left: 10px; width: 60px; text-align: center; word-break: keep-all; color: #4874C0; font-size: 12px'>自习室</P>
          <p class="nameClass" style='position: absolute; top: 44px; left: 76px; width: 60px; text-align: center; word-break: keep-all; color: #4874C0; font-size: 12px'>会员数</P>
          <p class="nameClass" style='position: absolute; top: 68px; left: 10px; width: 60px; text-align: center; font-weight: 600; word-break: keep-all; color: #00E8FF; font-size: 14px'>${number}</P>
          <p style='position: absolute; top: 68px; left: 76px; width: 60px; color: #FFBE51; font-weight: 600; color: #00E8FF; font-size: 14px; word-break: keep-all;'>${people}</P>`
        });
      }
      this.markerLayer.on('mouseover', mouseoverFunc)

      var mouseoutFunc = function(e){
        infoWindow.close();
      }
      this.markerLayer.on('mouseout', mouseoutFunc)

      for (var i = 0; i < this.studyInfoArr.length; i++) {
        this.infoWindowArr[i] = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(this.studyInfoArr[i].lat, this.studyInfoArr[i].lng),
          zIndex: 10, //信息窗的z-index值
          offset: { x: -30, y: -56 }, //向上偏移35像素坐标，向左偏移3像素坐标
          // content: `<div class="info_card"><div class="title"><span class="title_name">腾讯北京总部大楼</span><div class="close_img" onclick="closeInfoWindow()"><span class="min top_img"></span><span class="min right_img"></span><span class="min bottom_img"></span><span class="min left_img"></span></div></div><div align="left" class="content">点击右键变更内容</div><span class="cancle bot"></span><span class="cancle top"></span></div>`
          content: `<img src='${require('../assets/imgs/anchorImg.png')}' style='display: block; position: absolute;'></img>
          <p class="nameClass" style='position: absolute; top: 12px; left: 40px; word-break: keep-all; color: #FFFFFF; font-size: 12px'>会员：</P>
          <p style='position: absolute; top: 12px; left: 72px; color: #FFBE51; font-weight: 500; font-size: 12px; word-break: keep-all;'>${this.studyInfoArr[i].people}人</P>`
        });
      }
    },

    // 加载腾讯地图市级地图
    getTXCityInfo(){
      var geometries = this.studyInfoArr.map(item => {
        return {
          id: item.region_id,
          position: new TMap.LatLng(item.lat, item.lng),
          "styleId": 'marker',  //指定样式id
          // content: item.address
        }
      })
      // 图层类
      this.markerLayer = new window.TMap.MultiMarker({
        map: this.map,
        styles: {
          "marker": new TMap.MarkerStyle({
            "width": 10,
            "height": 30,
            "anchor": { x: 16, y: 32 },
            "src": require('../assets/imgs/taggingImg.png')
          })
        },
        geometries: geometries
      })
      this.markerLayer.updateGeometries(geometries)

      let that = this
      var eventClick = function(evt) {
        console.log(evt.geometry.id)
        that.cityName = ''
        that.roomName = ''

        that.provinceAreaType = 'roomLevel'
        that.map.setCenter(new TMap.LatLng(that.studyInfoArr[0].lat, that.studyInfoArr[0].lng))

        that.zoom = 12
        that.map.zoomTo(that.zoom);

        that.markerLayer.setGeometries([])
        that.infoWindowArr.forEach(item => {
          item.close()
        });
        infoWindow.close()

        that.backBool = true

        that.areaRegion_id = evt.geometry.id
        that.getAreaData()
      }
      this.markerLayer.on('click', eventClick)

      var mouseoverFunc = function(e){
        console.log(e)
        let name;
        let number;
        let people;
        that.studyInfoArr.forEach(item => {
          if(e.geometry.id == item.region_id){
            name = item.title
            number = item.number
            people = item.people
          }
        });

        infoWindow = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(e.geometry.position.lat, e.geometry.position.lng),
          zIndex: 10, //信息窗的z-index值
          offset: { x: -30, y: -108 }, //向上偏移35像素坐标，向左偏移3像素坐标
          content: `<img src='${require('../assets/imgs/countryInfoImg.png')}' style='display: block; position: absolute;'></img>
          <p class="nameClass" style='position: absolute; top: 13px; left: 10px; width: 128px; text-align: center; word-break: keep-all; color: #FFFFFF; font-size: 12px'>${name}</P>
          <p class="nameClass" style='position: absolute; top: 44px; left: 10px; width: 60px; text-align: center; word-break: keep-all; color: #4874C0; font-size: 12px'>自习室</P>
          <p class="nameClass" style='position: absolute; top: 44px; left: 76px; width: 60px; text-align: center; word-break: keep-all; color: #4874C0; font-size: 12px'>会员数</P>
          <p class="nameClass" style='position: absolute; top: 68px; left: 10px; width: 60px; text-align: center; font-weight: 600; word-break: keep-all; color: #00E8FF; font-size: 14px'>${number}</P>
          <p style='position: absolute; top: 68px; left: 76px; width: 60px; color: #FFBE51; font-weight: 600; color: #00E8FF; font-size: 14px; word-break: keep-all;'>${people}</P>`
        });
      }
      this.markerLayer.on('mouseover', mouseoverFunc)

      var mouseoutFunc = function(e){
        infoWindow.close();
      }
      this.markerLayer.on('mouseout', mouseoutFunc)

      for (var i = 0; i < this.studyInfoArr.length; i++) {
        this.infoWindowArr[i] = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(this.studyInfoArr[i].lat, this.studyInfoArr[i].lng),
          zIndex: 10, //信息窗的z-index值
          offset: { x: -30, y: -56 }, //向上偏移35像素坐标，向左偏移3像素坐标
          // enableCustom: true,//  自定义marker
          // content: `<div class="info_card"><div class="title"><span class="title_name">腾讯北京总部大楼</span><div class="close_img" onclick="closeInfoWindow()"><span class="min top_img"></span><span class="min right_img"></span><span class="min bottom_img"></span><span class="min left_img"></span></div></div><div align="left" class="content">点击右键变更内容</div><span class="cancle bot"></span><span class="cancle top"></span></div>`
          content: `<img src='${require('../assets/imgs/anchorImg.png')}' style='display: block; position: absolute;'></img>
          <p class="nameClass" style='position: absolute; top: 12px; left: 40px; word-break: keep-all; color: #FFFFFF; font-size: 12px'>会员：</P>
          <p style='position: absolute; top: 12px; left: 72px; color: #FFBE51; font-weight: 500; font-size: 12px; word-break: keep-all;'>${this.studyInfoArr[i].people}人</P>`
        });
      }
    },

    // 加载腾讯地图区级地图
    getTXAreaInfo(){
      var geometries = this.areaDataArr.map(item => {
        return {
          id: item.store_id,
          position: new TMap.LatLng(item.lat, item.lng),
          "styleId": 'marker',  //指定样式id
          // content: item.address
        }
      })
      // 图层类
      this.markerLayer = new window.TMap.MultiMarker({
        map: this.map,
        styles: {
          "marker": new TMap.MarkerStyle({
            "width": 10,
            "height": 30,
            "anchor": { x: 16, y: 32 },
            "src": require('../assets/imgs/taggingImg.png')
          })
        },
        geometries: geometries
      })
      this.markerLayer.updateGeometries(geometries)

      let that = this
      var eventClick = function(evt) {
        console.log(evt.geometry.id)
        that.provinceAreaType = 'areaLevel'
        that.memberInfoArr = []
        that.areaDataArr.forEach(item => {
          if(evt.geometry.id == item.store_id){
            that.memberInfoArr = item.people_list
            that.roomName = item.store_name
            that.areaAdress = item.address
            console.log(that.memberInfoArr)
          }
        });
      }
      this.markerLayer.on('click', eventClick)

      for (var i = 0; i < this.areaDataArr.length; i++) {
        this.infoWindowArr[i] = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(this.areaDataArr[i].lat, this.areaDataArr[i].lng),
          zIndex: 10, //信息窗的z-index值
          offset: { x: -30, y: -116 }, //向上偏移35像素坐标，向左偏移3像素坐标
          // enableCustom: true,//  自定义marker
          // content: `<div class="info_card"><div class="title"><span class="title_name">腾讯北京总部大楼</span><div class="close_img" onclick="closeInfoWindow()"><span class="min top_img"></span><span class="min right_img"></span><span class="min bottom_img"></span><span class="min left_img"></span></div></div><div align="left" class="content">点击右键变更内容</div><span class="cancle bot"></span><span class="cancle top"></span></div>`
          content: `<img src='${require('../assets/imgs/bigAnchorImg.png')}' style='display: block; position: absolute;'></img>
          <p class="nameClass" style='position: absolute; top: 12px; left: 10px; width: 120px; font-weight: 500; text-align: center; word-break: keep-all; color: #FFFFFF; font-size: 12px'>${this.areaDataArr[i].store_name}-自习室</P>
          <p class="nameClass" style='position: absolute; top: 43px; left: 10px; width: 120px; text-align: center; word-break: keep-all; color: #4874C0; font-size: 12px'>会员数</P>
          <p style='position: absolute; top: 62px; left: 10px; width: 120px; text-align: center; color: #00E8FF; font-weight: 600; font-size: 18px; word-break: keep-all;'>${this.areaDataArr[i].people_number}</P>`
        });
      }
    },

    // 处理经纬度坐标
    handleCoorFunc(){
      this.studyInfoArr.forEach(item => {
        let coorArr = [item.longitude, item.latitude]
        this.coordinateArr.push(coorArr)
      });
      console.log(this.coordinateArr)
    },
    // 获取当前时间
    getNowTime(){
      // 创建一个新的Date对象，表示当前时间
      var currentDate = new Date();

      // 获取年、月、日、小时、分钟和秒
      var year = currentDate.getFullYear();
      var month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以要加1，并补零
      var day = currentDate.getDate().toString().padStart(2, '0'); // 补零
      var hours = currentDate.getHours().toString().padStart(2, '0'); // 补零
      var minutes = currentDate.getMinutes().toString().padStart(2, '0'); // 补零
      var seconds = currentDate.getSeconds().toString().padStart(2, '0'); // 补零

      // 拼接日期和时间字符串
      this.dateStr = year + '/' + month + '/' + day

      var a = new Array("日", "一", "二", "三", "四", "五", "六");
      var week = new Date().getDay();
      this.weekStr = "周"+ a[week] + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    // 返回上一级
    backClick(){
      if(this.provinceAreaType == 'cityLevel'){//   省级
        this.map.setCenter(new TMap.LatLng(39.228173, 116.230349))
        this.zoom = 4.8
        this.map.zoomTo(this.zoom);

        this.markerLayer.setGeometries([])
        this.infoWindowArr.forEach(item => {
          item.close()
        });
        this.typeStr = '0'
        this.region_id = ''
        this.getCountryData()
        this.backBool = false
        this.provinceAreaType = ''
      } else if (this.provinceAreaType == 'roomLevel') {//   市级
        this.zoom = 7.4
        this.map.setZoom(this.zoom);
        // 清除所有marker
        this.markerLayer.setGeometries([])
        this.infoWindowArr.forEach(item => {
          item.close()
        });
        this.typeStr = '1'
        this.getCountryData()
        this.backBool = true
        this.cityName = ''
        this.roomInfoArr = []
        this.provinceAreaType = 'cityLevel'
      } else if(this.provinceAreaType == 'areaLevel'){//   市级
        // 清除所有marker
        this.markerLayer.setGeometries([])
        this.infoWindowArr.forEach(item => {
          item.close()
        });
        this.typeStr = '1'
        this.getAreaData()
        this.backBool = true
        this.provinceAreaType = 'roomLevel'
      }
    },

    // 放大地图
    enlargeMapClick(){
      if(this.zoom > 17.5){
        this.zoom = 18
      }else{
        this.zoom = this.zoom + 0.5
      }
      this.map.zoomTo(this.zoom);
    },

    // 缩小地图
    narrowMapClick(){
      if(this.zoom < 3.4){
        this.zoom = 3
      }else{
        this.zoom = this.zoom - 0.5
      }
      this.map.zoomTo(this.zoom);
    },

    // 自习室数量视图
    roomItemClick(item){
      this.typeStr = '1'
      this.region_id = item.region_id
      this.backBool = true
      this.getCountryData()
      //
      this.map.setCenter(new TMap.LatLng(this.studyInfoArr[0].lat, this.studyInfoArr[0].lng))
      this.map.zoomTo(this.zoom);
      this.markerLayer.setGeometries([])
      this.infoWindowArr.forEach(item => {
        item.close()
      });
    },

    // 自习室人数视图
    roomItemClickTwo(item){
      this.areaRegion_id = item.region_id
      this.getAreaData()
      this.map.setCenter(new TMap.LatLng(this.studyInfoArr[0].lat, this.studyInfoArr[0].lng))
      this.zoom = 12
      this.map.zoomTo(this.zoom);
      this.markerLayer.setGeometries([])
      this.infoWindowArr.forEach(item => {
        item.close()
      });
      this.backBool = true
    },

    roomClick(item) {
      if (this.provinceAreaType == '') {
        this.provinceAreaType = 'cityLevel'
        this.zoom = 7.4
        this.roomItemClick(item)
      } else if (this.provinceAreaType == 'cityLevel') {
        this.provinceAreaType = 'roomLevel'
        this.roomItemClickTwo(item)
      } else if (this.provinceAreaType == 'roomLevel') {
        this.backBool = true
        this.provinceAreaType = 'areaLevel'
        this.roomName = item.store_name
        this.areaAdress = item.address
        this.memberInfoArr = item.people_list
      }
    },

    /** 回调函数 地图渲染好时触发 不需要可以删掉 */
    onAPILoaded() {
      this.loaded = true;
      console.log("执行对地图的其他操作");
      // 在这里可以执行其他与地图相关的操作
    },
    // 移入
    mouseEnter(){
        this.highLightImg = require('../assets/imgs/HighlightImg.png')
        console.log('鼠标移入--')
    },
    // 移除
    mouseLeave(){
        this.highLightImg = require('../assets/imgs/routineImg.png')
        console.log('鼠标移出++')
    },
    goHiYouCaiFunc(){
      this.$router.push('/hiYouCai');
    }
  }
}
</script>

<style lang="scss" scoped>
.largeScreen{
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #091a30;
}
#mapContainer {
  position: fixed;
  width: 100%;
  height: calc(100vh - 6px);
}
.topViewClass{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-image: url('../assets/imgs/titleTopImg.png');
  background-size: 100% 100%;
}
.titleTopClass{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.topStripView{
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 24px;
  bottom: 0;
  right: 50px;
  width: 500px;
  height: 100%;
}
.timeDivClass{
  display: flex;
  flex-direction: column;
  width: 126px;
}
.topStripClass{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-size: 100% 100%;
}
.timeClass{
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: white;
  letter-spacing: 2px;
  text-align: right;
}
.timeView{
  width: 60px;
  margin: auto 40px;
  text-align: right;
}
.leftView{
  position: absolute;
  left: 0;
  margin-top: 120px;
  width: calc(100vw - 3% - 27vw);
  height: 15%;
}
.leftTopView{
  display: flex;
  width: 100%;
  height: 100px;
}
.arrowView{
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  height: 100%;
}

.arrowImgClass{
  margin:0px auto auto;
  width: 40px;
  height: 50px;
  background-size: 100% 100%;
  animation: example 2s infinite linear;
}

@keyframes example {
  0% {transform: translateY(0);}
  25% {transform: translateY(-10px);}
  50% {transform: translateY(0);}
  75% {transform: translateY(10px);}
  100% {transform: translateY(0);}
}

.diskImgClass{
  margin: 0 auto;
  width: 160px;
  height: 80px;
  background-size: 100% 100%;
}
.overallClass{
  flex: 1;
  margin: 0 auto;
  display: flex;
  height: 100%;
}
.zixiClass{
  min-width: 110px;
  margin: auto 10px auto -90px;
  font-family: YouSheBiaoTiHei;
  font-size: 26px;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(255,255,255,0.5);
  font-weight: bolder;
}
.rightZiXXi{
  margin-left: 5%;
}
.numberViewClass{
  position: relative;
  margin: auto 0 auto 13px;
  width: 32px;
  height: 38px;
  // background: linear-gradient( 135deg, #1B4880 0%, #000000 100%);
  // border: 2px solid;
  // border-image: linear-gradient(180deg, rgba(155, 201, 255, 1), rgba(255, 255, 255, 1), rgba(140, 194, 255, 1)) 2 2;
  // border-radius: 3px;
  // line-height: 38px;
}
.numberTextClass{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: white;
  font-size: 22px;
  font-weight: bolder;
}
.rightView{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 3%;
  width: 27vw;
  max-height: calc(100% - 120px);
}
.seleStudyView{
  position: relative;
  margin-top: 50px;
}
.roomViewClass{
  position: relative;
  width: 100%;
  height: 56px;
}
.roomAddressClass{
  width: 100%;
  height: 56px;
  background-size: 100% 100%;
}
.roomViewTextClass{
  position: absolute;
  left: 14%;
  top: 10px;
  font-size: 15px;
  color: white;
}
.selfStudyClass{
  width: 100%;
  height: 41px;
  background-size: 100% 100%;
}
.columnClass{
  position: absolute;
  left: 1.5%;
  top: -12px;
  width: 7.8%;
  height: 50px;
}
.selfStudyName{
  position: absolute;
  left: 14%;
  top: 7px;
  font-size: 20px;
  color: #FFFFFF;
  font-weight: bolder;
}
.infoView{
  width: 100%;
  max-height: calc(100% - 100px);
  background-color: #010F1E50;
  overflow-y: auto;
}
.studyItemView{
  height: 76px;
  margin: 0 10px;
  border-bottom: 1px solid #A0E9FF;
  cursor: pointer;
}
.itemTopView{
  display: flex;
  justify-content: space-between;
}
.studyName{
  margin: 12px 0 0 0px;
  color: white;
  font-size: 18px;
  text-align: left;
  font-family: MiSans, MiSans;
  font-weight: 600;
}
.studentBackView{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 100px); 
  background-color: #010F1E50;
  overflow-y: auto;
}
.studyInfoView{
  margin: 10px;
  background-color: #010F1E70;
  border-radius: 6px;
  padding: 0 10px 10px 10px;
  max-height: 100%;
}
.studentClass{
  margin: 12px 0 0 0px;
  color: white;
  font-size: 16px;
  text-align: left;
  font-family: MiSans, MiSans;
  font-weight: 600;
}
.genderAgeClass{
  margin: 10px 4px 5px 10px;
  font-family: MiSans, MiSans;
  font-weight: 600;
  font-size: 16px;
  color: white;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
}
.studentAddressView{
  text-align: left;
  font-size: 14px;
  color: white;
  margin: 0 4px 0px 2px;
}
.peopleNumber{
  margin: 10px 4px 5px 10px;
  font-family: MiSans, MiSans;
  font-weight: 600;
  font-size: 38rpx;
  color: #FFBE51;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
}
.itemBottomView{
  display: flex;
}
.addressImg{
  margin: 5px 4px auto 2px;
  width: 9px;
  height: 10px;
}
.middleView{
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0;
}
.wantCountryClass{
  margin-top: 6px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 14px;
}
.labelView{
  margin-right: 6px;
  margin-bottom: 10px;
  padding: 4px 5px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  background-color: #ffffff20;
}

.itemAddressView{
  text-align: left;
  font-size: 14px;
  color: #8FB6FF;
  margin: auto 4px auto 2px;
}
.mapViewClass{
  display: flex;
  margin: auto 8% auto auto;
  width: 70%;
  height: 80%;
  background-color: #8FB6FF;
}
.radarView{
  position: absolute;
  left: 3%;
  bottom: 7%;
}
.radarImgClass{
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
}
.scanningClass{
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform-origin: left top;
    transform: rotate(360deg);
  }
  to {
    transform-origin: left top;
    transform: rotate(0deg);
  }
}

.numberImgClass{
  width: 32px;
  height: 38px;
  background-size: 100% 100%;
}
.labelContent{
  background-color: #091a30 !important;
  width: 200px;
  height: 100px;
}
.showInfoWindow{
  position: relative;
}
.nameClass{
  position: absolute;
  top: 0;
  left: 0;
}
.backLevelView{
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 120px;
  height: 40px;
  transform: translate3d(-50%, -50%, 0);
}
.backLevelImgClass{
  width: 120px;
  height: 40px;
  background-size: 100% 100%;
  cursor: pointer;
}
.enlargeClass{
  margin: 5px 25px 5px 35px;
  width: 28px;
  height: 28px;
  background-size: 100% 100%;
  cursor: pointer;
}
.narrowClass{
  margin: 5px 0px;
}
.highlightView{
  position: relative;
  width: 155px;
  height: 43px;
}
.highlightClass{
  width: 155px;
  height: 43px;
}
.highlightText{
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 43px;
  line-height: 43px;
  font-size: 16px;
  font-weight: bolder;
  color: #FFFFFF;
  cursor: pointer;
}
.normalText{
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 43px;
  line-height: 43px;
  font-size: 16px;
  font-weight: bolder;
  color: #FFFFFF50;
  cursor: pointer;
}
.normalText:hover{
  color: white;
}
.routineClass{
  width: 155px;
  height: 43px;
}
</style>

<style>
.amap-marker-label{
  padding: 0;
  background-color: #193B7B00;
  border: 1px solid #193B7B00;
}
.amap-info-content{
  padding: 0;
  background-color: #193B7B00;
  border: 1px solid #193B7B00;
}
.bottom-center .amap-info-sharp{
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}
.amap-logo{
  /* display: none !important; */
}
.amap-copyright{
  opacity:0;
}

#mapContainer div > div > div > div {
  /* background-color: transparent !important;  */
  /* 可以覆盖默认的背景颜色 */
  /* 其他样式 */
  padding: 0 !important;
  width: 0 !important;
  height: 0 !important;
  /* display: none; */
  /* width: 0;
  height: 0; */
  /* overflow: hidden; */
  /* visibility: hidden; */

  /* background-color: transparent !important; */
  /* z-index: -100; */
  /* position: absolute;
  left: -9999px; */
}
#mapContainer div > div > div > div > svg{
  /* width: 5px;
  height: 5px;
  display: none;
  visibility: hidden;
  background-color: transparent !important; */
}
#mapContainer div > div > div > div > a> img{
  /* width: 5px; */
  /* height: 5px; */
  /* display: none; */
  /* visibility: hidden;
  background-color: transparent !important; */
}
</style>
